<!-- @format -->

<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col md="3" lg="12" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>Pages</label>
          <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
          <b-button variant="primary" :to="{ name: 'vip_insurance-policy_create' }" style="width:150px;"> Add </b-button>
          <b-button variant="primary" @click="exportXlsx" :disabled="isLoading" class="ml-2" style="width:150px;"> Export </b-button>
          <!--          高级搜索-->
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-toggle.collapse-1 variant="outline-primary" class="ml-2"> Advanced search</b-button>
        </b-col>

        <!-- Search -->
        <!--        <b-col md="2" lg="12">-->
        <!--          <div class="d-flex align-items-center justify-content-end">-->
        <!--            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Policy number" />-->
        <!--          </div>-->
        <!--        </b-col>-->
        <!--        <b-col md="2">-->
        <!--          <div class="d-flex align-items-center justify-content-end">-->
        <!--            <b-form-input v-model="user_id" class="d-inline-block mr-1" placeholder="vip id" />-->
        <!--          </div>-->
        <!--        </b-col>-->
        <!--        <b-col md="2">-->
        <!--          <div class="d-flex align-items-center justify-content-end">-->
        <!--            <b-form-input v-model="protect_user" class="d-inline-block mr-1" placeholder="protect_user" />-->
        <!--          </div>-->
        <!--        </b-col>-->
        <!--        <b-col md="2">-->
        <!--          <div class="d-flex align-items-center justify-content-end">-->
        <!--            <b-form-input v-model="id" class="d-inline-block mr-1" placeholder="ID" />-->
        <!--          </div>-->
        <!--        </b-col>-->
        <!-- <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="id" class="d-inline-block mr-1" placeholder="vip id" />
          </div>
           <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="protect_user" class="d-inline-block mr-1" placeholder="protect_user" />
          </div> -->
      </b-row>
      <b-collapse id="collapse-1" class="mt-2">
        <b-card class="border mb-0">
          <!--          高级搜索->被保人-->
          <b-row>
            <b-col md="3" lg="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label style="min-width: 120px">Insured:</label>
              <b-form-input v-model="protect_user" class="d-inline-block mr-1" placeholder="Insured" />
            </b-col>
            <b-col md="3" lg="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label style="min-width: 120px">Agent name</label>
              <b-form-input v-model="member" class="d-inline-block mr-1" placeholder="Agent name" />
            </b-col>
            <b-col md="3" lg="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label style="min-width: 120px">Police Number</label>
              <b-form-input v-model="order_number" class="d-inline-block mr-1" placeholder="Police Number" />
            </b-col>
            <b-col md="3" lg="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label style="min-width: 120px">Pending</label>
              <v-select v-model="pending" :options="statusOptions" :clearable="true" placeholder="Select Pending" style="margin-right:5px;width:600px;"/>
            </b-col>
             <b-col md="3" lg="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0" style="margin-top:10px;">
              <label style="min-width: 120px">Inforce</label>
              <v-select v-model="inforce" :options="statusOptions2" :clearable="true" placeholder="Select Inforce" style="margin-right:5px;width:600px;"/>
            </b-col>
          </b-row>
        </b-card>
      </b-collapse>
    </div>
    <div>
      <b-table ref="orderListTable" :items="fetchInvoices" responsive :fields="tableColumns" primary-key="id" show-empty empty-text="No matching records found" class="position-relative  text-center">
        <template #head(invoiceStatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <div class="font-medium-1">{{ data.item.member.real_name }}</div>
          <div>{{ data.item.created.level.name }}</div>
          <div class="grid-hero" style="color: #28c76f">
            {{ data.item.member.id }}
          </div>
        </template>

        <!-- Column: Invoice Status -->
        <template #cell(status)="data">
          <b-badge :variant="data.value | filterStatusColor" class="mx-auto">{{ data.value | filterStatusTxt }}</b-badge>
        </template>

        <!-- Column: Issued Date -->
        <template #cell(issuedDate)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <template #cell(share_number)="data">
          <b-link>
            #<span class="text-nowrap" @click="getMoneyInfo(data.item, data.item.id)"> {{ data.item.share_rate.length }} People </span>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-link @click="setItem(data)">
            <feather-icon icon="EyeIcon" size="21" />
          </b-link>
        </template>
      </b-table>
    </div>

    <b-modal id="audit-modal" cancel-variant="outline-secondary" ok-title="Confirm" cancel-title="Cancel" centered title="Review Policy">
      <validation-observer ref="auditForm">
        <b-form>
          <b-form-group label="Policy TP:">
            <validation-provider #default="{ errors }" name="Policy TP" rules="required">
              <b-form-input v-model="checkForm.order_tp" type="email" placeholder="Policy TP" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!--          <b-form-group>-->
          <!--            <label>Policy Status</label>-->
          <!--            <validation-provider #default="{ errors }" name="Review Status" rules="required">-->
          <!--              <v-select-->
          <!--                v-model="checkForm.status"-->
          <!--                :reduce="item => item.value"-->
          <!--                :placeholder="'Please Select Status'"-->
          <!--                :state="errors.length > 0 ? false : null"-->
          <!--                :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"-->
          <!--                :options="[-->
          <!--                  { label: 'Submitted', value: 1 },-->
          <!--                  { label: 'Reviewed', value: 2 },-->
          <!--                  { label: 'Active', value: 3 },-->
          <!--                ]" />-->
          <!--              <small class="text-danger">{{ errors[0] }}</small>-->
          <!--            </validation-provider>-->
          <!--          </b-form-group>-->
          <!--        改为单选按钮  -->
          <b-form-group label="Policy Status">
            <!--            <label>Policy Status</label>-->
            <validation-provider #default="{ errors }" name="Review Status" rules="required">
              <b-form-radio-group v-model="checkForm.status" :state="errors.length > 0 ? false : null">
                <!--                <b-form-radio value="1">Submitted</b-form-radio>-->
                <b-form-radio value="2">Reviewed</b-form-radio>
                <b-form-radio value="3">Active</b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <!--   footer   -->
      <template #modal-footer>
        <b-button variant="secondary" @click.prevent="$bvModal.hide('audit-modal')">Cancel</b-button>
        <b-button variant="primary" @click.prevent="auditInvoice">Confirm</b-button>
      </template>
    </b-modal>

    <b-modal id="money-modal" cancel-variant="outline-secondary" ok-title="Confirm" cancel-title="Cancel" centered title="Split Info" size="lg">
      <b-table :items="fenqianField" responsive :fields="fenqianFieldColumn" primary-key="id" show-empty empty-text="No matching records found" class="position-relative" />
      <!--   footer   -->
      <template #modal-footer>
        <b-button variant="secondary" @click.prevent="$bvModal.hide('money-modal')">Close</b-button>
      </template>
    </b-modal>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRadio, BFormRadioGroup, BCollapse, VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { reactive, getCurrentInstance, nextTick, ref, watch } from '@vue/composition-api'
import { required } from '@core/utils/validations/validations'
import axiosIns from '@/libs/axios'
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import useInvoicesList from './useOrderList'
import StoreModule from './OrderManagerList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormRadio,
    BFormRadioGroup,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  filters: {
    filterStatusTxt(value) {
      switch (value) {
        case 1:
          return 'Submitted'
        case 2:
          return 'Reviewed'
        case 3:
          return 'Active'
        case 4:
          return 'Completed'
      }
    },
    filterStatusColor(value) {
      switch (value) {
        case 1:
          return 'primary'
        case 2:
          return 'success'
        case 3:
          return 'success'
        default:
          return 'success'
      }
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'user-orders'
    const { proxy } = getCurrentInstance()
    // Register module
    if (!store.hasModule('user-orders')) {
      store.registerModule('user-orders', StoreModule)
    }

    const {
      auditInvoice: auditInvoiceStore,
      fetchInvoices,
      tableColumns,
      perPage,
      pending,
      inforce,
      currentPage,
      protect_user,
      totalInvoices,
      dataMeta,
      perPageOptions,
      statusOptions,
      statusOptions2,
      id,
      searchQuery,
      member,
      order_number,
      orderListTable,
      deleteInvoice,
      refetchData,
      user_id,
    } = useInvoicesList(proxy)

    const checkForm = reactive({
      order_tp: '',
      status: '',
      id: '',
    })
    const auditInvoice = () => {
      proxy.$refs.auditForm.validate().then(isOk => {
        if (isOk) {
          auditInvoiceStore(checkForm)
        } else {
          // console.log('error')
        }
      })
    }
    const fenqianField = reactive([])
    const fenqianFieldColumn = [
      {
        key: 'user_id',
        label: 'Agent ID',
      },
      {
        key: 'real_name',
        label: 'Agent name',
      },
      {
        key: 'level',
        label: 'Agent Level',
      },
      {
        key: 'share_rate',
        label: 'Split Percent',
        formatter: (value, row) => `${value}%`,
      },
      // {
      //   key: 'tp',
      //   label: 'Split TP',
      //   formatter: (value, row) => `${value}`,
      // },
      // {
      //   key: 'money',
      //   label: 'Split Amount',
      // },
      {
        label: 'Status',
        key: 'stu_name',
      },
    ]

    const getMoneyInfo = (item, id) => {
      axiosIns.get('/order/getRateInfo', { params: { order_id: id } }).then(response => {
        if (response.code == 0) {
          proxy.$bvModal.show('money-modal')
          nextTick(() => {
            fenqianField.splice(0, fenqianField.length, ...response.data)
          })
        } else {
          proxy.$bvToast.toast(response.msg, {
            title: 'Tips',
            variant: 'danger',
            solid: true,
            appendToast: true,
            autoHideDelay: 2000,
          })
        }
      })
    }
    const setItem = data => {
      store.commit('user-orders/setOrderDetail', data.item)

      setTimeout(() => {
        router.push({ name: 'vip_insurance-policy-detail' })
      }, 200)
    }
    const setItemEdit = data => {
      store.commit('user-orders/setOrderDetail', data.item)

      setTimeout(() => {
        router.push({ name: 'vip_insurance-policy_edit' })
      }, 200)
    }

    // 请求接口保存文件
    const isLoading = ref(false)
    const exportXlsx = () => {
      // if (isLoading.value) return
      //
      // isLoading.value = true
      proxy.$bvToast.toast('Exporting...', {
        title: 'Tips',
        variant: 'primary',
        solid: true,
        appendToast: true,
        autoHideDelay: 2000,
      })
      // 原始 http 请求
      // const httpRequest = new XMLHttpRequest()
      // httpRequest.open('get', 'http://127.0.0.1:8000/backend/order/export')
      // // 设置请求头
      // httpRequest.setRequestHeader('token', localStorage.getItem('accessToken'))
      // httpRequest.responseType = 'blob'
      //
      // httpRequest.send()
      // 判断请求是否成功
      // httpRequest.onreadystatechange = function () {
      //   if (httpRequest.readyState === 4 && httpRequest.status === 200) {
      //     isLoading.value = false
      //     // 获取到文件的二进制数据
      //     const blob = httpRequest.response
      //     // 创建一个blob对象
      //     const blobObject = new Blob([blob], { type: 'application/vnd.ms-excel' })
      //     // 创建一个a标签
      //     const a = document.createElement('a')
      //     // 创建一个url对象
      //     const url = URL.createObjectURL(blobObject)
      //     // 将a标签的href属性设置为url对象
      //     a.href = url
      //     // 设置a标签的download属性
      //     a.download = '订单列表.xlsx'
      //     // 触发a标签的点击事件
      //     a.click()
      //     // 取消url对象
      //     URL.revokeObjectURL(url)
      //   } else {
      //     proxy.$bvToast.toast('导出失败', {
      //       title: 'Tips',
      //       variant: 'danger',
      //       solid: true,
      //       appendToast: true,
      //       autoHideDelay: 2000,
      //     })
      //   }
      // }

      // 改进为 axios 请求
      axiosIns('order/export', {
        responseType: 'blob',
      })
        .then(res => {
          isLoading.value = false
          proxy.$bvToast.toast('Export Successful', {
            title: 'Tips',
            variant: 'success',
            solid: true,
            appendToast: true,
            autoHideDelay: 2000,
          })
          // console.log(res)
          const blob = res
          const blobObject = new Blob([blob], {
            type: 'application/vnd.ms-excel',
          })
          // 创建一个a标签
          const a = document.createElement('a')
          // 创建一个url对象
          const url = URL.createObjectURL(blobObject)
          // 将a标签的href属性设置为url对象
          a.href = url
          // 设置a标签的download属性
          a.download = 'Policy_List.xlsx'
          // 触发a标签的点击事件
          a.click()
          // 取消url对象
          URL.revokeObjectURL(url)
        })
        .catch(() => {
          isLoading.value = false
          proxy.$bvToast.toast('Export Failure', {
            title: 'Tips',
            variant: 'danger',
            solid: true,
            appendToast: true,
            autoHideDelay: 2000,
          })
        })

      // httpRequest.onload = () => {
      //   const blob = new Blob([httpRequest.response])
      //   FileSaver.saveAs(blob, 'order.xlsx')
      // }
    }

    return {
      user_id,
      isLoading,
      exportXlsx,
      setItem,
      setItemEdit,
      fenqianField,
      fetchInvoices,
      tableColumns,
      perPage,
      pending,
      inforce,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      statusOptions,
      statusOptions2,
      searchQuery,
      id,
      orderListTable,
      protect_user,
      refetchData,
      deleteInvoice,
      auditInvoice,
      checkForm,
      required,
      getMoneyInfo,
      fenqianFieldColumn,
      member,
      order_number,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 100px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
