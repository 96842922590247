/** @format */

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosIns from '@/libs/axios'

export default function useInvoicesList(proxy) {
  // Use toast
  const toast = useToast()

  const orderListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // {
    //   key: 'selected',
    //   label: 'SD',
    //   thStyle: {
    //     width: '30px',
    //     textAlign: 'center'
    //   },
    // },
    {
      key: 'status',
      label: 'STATUS',
      thStyle: {
        whiteSpace: 'nowrap',
        width:'120px'
      },
    },
    {
      key: 'actions',
      label: 'Details',
      thStyle: { width: '20px', textAlign: 'center', whiteSpace: 'nowrap' },
    },
    {
      key: 'id',
      label: 'Agent',
      thStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      label: 'POLICY NO.',
      key: 'number',
      thStyle: { whiteSpace: 'nowrap' },
    },
    {
      label: 'PRODUCT',
      key: 'product.title',
      thStyle: { whiteSpace: 'nowrap' },
    },
    // { key: 'create_level', label: '等级' },
    // {
    //   key: 'number',
    //   label: '保单',
    // },

    {
      label: 'SPLITS',
      key: 'share_number',
      thStyle: { whiteSpace: 'nowrap' },
    },
    {
      key: 'protect_user',
      label: 'INSURED',
      formatter: value => (!value ? '-- 无 --' : `${value}`),
      thStyle: { whiteSpace: 'nowrap' },
    },
    // {
    //   key: 'product.title',
    //   label: '公司',
    // },

    // {
    //   label: '佣金类型',
    //   key: 'count_cate',
    //   formatter: value => (!value ? '-- 无 --' : `${value}`),
    //   thStyle: {
    //     whiteSpace: 'nowrap',
    //   },
    // },
    {
      label: 'Total TP',
      key: 'order_tp',
      thStyle: {
        whiteSpace: 'nowrap',
      },
      formatter: value => `${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
    },
    // {
    //   label: '派发率(%)',
    //   key: 'rate',
    //   thStyle: {
    //     whiteSpace: 'nowrap',
    //   },
    // },
    // {
    //   label: '年金保费',
    //   key: 'year_money',
    //   thStyle: {
    //     whiteSpace: 'nowrap'
    //   },
    // },
    // {
    //   label: '付费百分比',
    //   key: 'fee_rate',
    //   formatter: value => (!value ? '-- 无 --' : `${value}%`),
    //   thStyle: {
    //     whiteSpace: 'nowrap'
    //   },
    // },
    {
      key: 'all_money',
      label: 'PAID COMMISSION',
      thStyle: {
        whiteSpace: 'nowrap',
      },
      formatter: value => (value ? `$${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : value),
    },
    {
      label: 'Submit Date',
      key: 'created_at',
      thStyle: {
        whiteSpace: 'nowrap',
      },
    },
    // {
    //   key: 'submit_at',
    //   label: 'Submit Date',
    // },
    // {
    //   key: 'apply_time',
    //   label: '生效日期',
    // },
  
    // {
    //   key: 'desc',
    //   label: 'Remarks',
    //   formatter: value => (!value ? '-- 无 --' : `${value}`),
    //   thStyle: {
    //     whiteSpace: 'nowrap',
    //   },
    // },
  ]
  const perPage = ref(100) // pageSize
  const pending = ref('')
  const inforce = ref('')
  const totalInvoices = ref(0) // total
  const currentPage = ref(1) // now Page
  const perPageOptions = [100,200,500,1000] // pageSize Array
  const statusOptions = ['Submitted','Reviewed']
  const statusOptions2 = ['Active','Completed']
  const searchQuery = ref('') // searchQuery
  const protect_user = ref('') // searchQuery
  const member = ref('')
  const order_number = ref('')

  const startTime = ref('')
  const endTime = ref('')
  inforce.value = proxy.$route.query?.inforce || ''
  pending.value = proxy.$route.query?.pending || ''
  const dataMeta = computed(() => {
    const localItemsCount = orderListTable.value
      ? orderListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    orderListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery,pending,inforce, protect_user, member, order_number], () => {
    refetchData()
  })

  const fetchInvoices = (ctx, callback) => {
    axiosIns
      .get('/order/list', { params: {
        perPage: perPage.value,
        page: currentPage.value,
        // number: searchQuery.value,
        // user_id: user_id.value,
        startTime: startTime.value,
        endTime: endTime.value,
        protect_user: protect_user.value,
        member: member.value,
        order_number: order_number.value,
        pending: pending.value,
        inforce:inforce.value,
      } })
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalInvoices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    // store
    //   .dispatch('user-orders/fetchListData', {
    //     perPage: perPage.value,
    //     page: currentPage.value,
    //     number: searchQuery.value,
    //     // user_id: user_id.value,
    //     startTime: startTime.value,
    //     endTime: endTime.value,
    //   })
    //   .then(response => {
    //     const { list, total } = response.data
    //     callback(list)
    //     totalInvoices.value = total
    //   })
    //   .catch(() => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: "Error fetching invoices' list",
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   })
  }

  // 删除
  const deleteInvoice = val => {
    store
      .dispatch('user-order/orderDelete', val)
      .then(response => {
        if (response.code == 0) {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          refetchData()
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '删除错误!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // 审核
  const auditInvoice = val => {
    store
      .dispatch('user-order/orderAudit', val)
      .then(response => {
        if (response.code == 0) {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          proxy.$bvModal.hide('audit-modal')
          refetchData()
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '审核错误!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // 订单详情

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    pending,
    inforce,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    statusOptions,
    statusOptions2,
    searchQuery,
    orderListTable,
    deleteInvoice,
    auditInvoice,
    refetchData,
    startTime,
    endTime,
    protect_user,
    member,
    order_number
  }
}
