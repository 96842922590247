<template>
  <div>
    <OrderList />

  </div>
</template>

<script>
import OrderList from './OrderList/OrderList.vue'

export default {
  name: 'MyOrder',
  components: {
    OrderList,
  },

}
</script>
